$(function () {
    // Rotors search filtering

    filter_compatible_vehicles();


    $("#make").change(function () {

        filter_compatible_vehicles();
    });


    $("#model").change(function () {

        filter_year();
    });

    // Rotors search form submit

    $(".rotors_search_form").submit(function () {
        $('.part').remove();
        $("#parts_loading").show();
        $("#parts_loading").css('display', 'block');
    });

    // Create part submit

    $("#create_part_btn").click(function () {
        $("#parts_create_loading").show();
        $("#parts_create_loading").css('display', 'block');
    });

    // Click on a part's summary

    $("#parts_summary_table").on("click", "tr.part", function () {
            var url = $(this).attr("data-url");
            $(".part").removeClass('info');
            $(this).addClass('info');
            fetch_part_details(url);
        }
    );

    // Comments Type select box value changed

    $("#part_details").on("change", "#comment_type", function () {
        var comment_type = $("#comment_type option:selected").attr('value');
        filter_comments(comment_type);
    });

    //// Edit part modal

    // bind update button
    $("#edit_part_submit_btn").click(function () {
        $("#edit-part-modal form").submit();
        $("#edit_part_submit_btn").attr("disabled", "disabled");
    });

    // bind cancel button
    $("#edit_part_cancel_btn").click(function () {
        $('#edit-part-modal').modal('hide');
        $("#edit-part-modal .modal-body").html("");
    });

    // activate data tab on modal loaded
    $('#edit-part-modal').on('loaded', function (e) {
        $("#edit_part_submit_btn").removeAttr("disabled");
        $('#edit_part_compatible_vehicles_nav').click();
        $('#edit_part_data_nav').click();
    });

    // delete data on modal closed
    $('#edit-part-modal').on('hidden', function () {
        $("#edit-part-modal .modal-body").html("");
    });

    // bind nested form delete buttons
    $('#edit-part-modal').on('click', '.remove_fields', function (event) {
        event.preventDefault();
        var r = confirm("Delete ?");
        if (r == true) {
            $(this).prev('input[type=hidden]').val('1');
            $(this).closest('.nested_entity_row').hide("slow");
        }
    });

    // bind nested form add buttons
    $('#edit-part-modal').on('click', '.add_fields', function (event) {
        event.preventDefault();
        var regexp, time;
        time = new Date().getTime();
        regexp = new RegExp($(this).data('id'), 'g');
        $(this).before($(this).data('fields').replace(regexp, time));
    });
});

function filter_compatible_vehicles() {
    filter_model();
    filter_year();
}

function filter_model() {

    var $make = $("#make");
    var make = $("#make option:selected").text();
    var patt = new RegExp("^" + RegExp.escape(make) + "----");

    if ($make.data('options') == undefined) {

        /*Taking an array of all options-2 and kind of embedding it on the select1*/
        $make.data('options', $('#model option').clone());

    }

    var options = $make.data('options').filter(function () {
        var model_val = $(this).val();
        if (patt.test(model_val) || model_val === "") {
            return true;
        }
    });
    $('#model').html(options);
}


function filter_year() {

    var $model = $("#model");
    var make = $("#make option:selected").text();
    var model = $("#model option:selected").text();
    var patt = new RegExp("^" + RegExp.escape(make) + "----" + RegExp.escape(model) + "----");

    if ($model.data('options') == undefined) {

        /*Taking an array of all options-2 and kind of embedding it on the select1*/
        $model.data('options', $('#year option').clone());

    }

    var options = $model.data('options').filter(function () {
        var year_val = $(this).val();
        if (patt.test(year_val) || year_val === "") {
            return true;
        }
    });
    $('#year').html(options);
}


function filter_internals() {
    if ($('#show_internal').is(':checked')) {
        $(".internal").show();
    } else {
        $("#comment_type").val("Public");
        $("#comment_type option[value='Internal']").remove();
        $(".internal").hide();
    }
}

function refresh_parts() {
    refresh_selected_part();
    refresh_parts_search();
}

function refresh_selected_part() {
    var url = $('#part_details').data("url");
    fetch_part_details(url);
}

function refresh_parts_search() {
    var search_type = $('#search_type').val();
    if (search_type == "parts") {
        $("#parts_search_form").submit();
    }
    else if (search_type == "vehicles") {
        $("#vehicles_search_form").submit();
    }
}

function filter_comments(type) {
    if (!type) {
        type = $("#comment_type option:selected").attr('value');
    }

    $("li.comment").hide();
    $("." + type).show();
}

function fetch_part_details(url) {
    $('#part_details').html("");
    $("#part_details_loading").show();
    $("#part_details_loading").css('display', 'block');

    $.ajax_eval(url, "GET");
}

function create_part() {
    var selected_type = $("#rotor-select").find('option:selected').val();
    $("#select-part-modal").append("<a id='crete_part_link' href='/parts/new?type=" + selected_type + "' data-target='#edit-part-modal' class='internal modal-link'></a>");
    $("#rotor-select").trigger('submit');
    $("#rotor-select").prop('selectedIndex', 0);
    $('#crete_part_link').trigger('click');
    $("#crete_part_link").remove();
    $('#select-part-modal').modal('hide');
}